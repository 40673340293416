<template>
  <span>
       <span v-if="value>0 || (value !=null && value.length>0)">{{ value }}</span>
      <span v-else>---</span>
  </span>
</template>

<script>
export default {
  name: "checkValue",
  props: {
    value: null
  }
}
</script>

<style scoped>

</style>
