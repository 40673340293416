<template>
  <span class="table-price">
       <span v-if="(disPrice && disPrice>0 && disPrice<price) || canBeFree" :class="changeDir?'reverse':''">
               <span class="current-price">
                   {{ formatNumber(disPrice) }}
               </span>
         <span class="old-price" v-if="disPrice<price">
          {{ formatNumber(price) }}
         </span>
       </span>
    <span v-else class="current-price">{{ formatNumber(price) }}</span>
  </span>
</template>

<script>
export default {
  name: "checkPrice",
  props: {
    price: null,
    disPrice: null,
    changeDir: {
      type: Boolean,
      default: false
    },
    canBeFree: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
